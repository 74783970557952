/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseUrl: 'https://jesicartapi.fatagahunda.rw/public/api/', // EXAMPLE https://jesicartapi.fatagahunda.rw/public/api/
  imageUrl: 'https://jesicartapi.fatagahunda.rw/public/storage/images/', // https://jesicartapi.fatagahunda.rw/public/storage/images/
};
