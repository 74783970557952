<!-- 
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
 -->
<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">500</h1>
          <h4 class="pt-3">{{util.translate('Houston, we have a problem!')}}</h4>
          <p class="text-muted">{{util.translate('The page you are looking for is temporarily unavailable.')}}</p>
        </div>
        <div class="input-prepend input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </div>
          <input id="prependedInput" class="form-control" size="16" type="text" placeholder="What are you looking for?">
          <span class="input-group-append">
            <button class="btn btn-info" type="button">{{util.translate('Search')}}</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
