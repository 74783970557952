/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterEvent, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { Title } from '@angular/platform-browser';
import { UtilService } from './services/util.service';
import { ApiService } from './services/api.service';
import { navItems } from './_nav';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  public navItems = navItems;
  constructor(
    private router: Router,
    public iconSet: IconSetService,
    private titleService: Title,
    public util: UtilService,
    public api: ApiService,
    private chMod: ChangeDetectorRef
  ) {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage != null && selectedLanguage && selectedLanguage !== '' && selectedLanguage !== 'null') {
      this.getDefaultSettingsById();
    } else {
      this.getAppSettings();
    }
    iconSet.icons = { ...freeSet };
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  getDefaultSettingsById() {
    const param = {
      id: localStorage.getItem('selectedLanguage')
    }
    this.api.post('v1/setttings/getAppSettingsByLanguageId', param).then((data: any) => {
      console.log('app settings', data);
      if (data && data.status === 200 && data.success === true) {
        const lang = data && data.data && data.data.language ? data.data.language : null;
        if (lang && lang !== null) {
          this.util.translations = JSON.parse(lang.content);
          localStorage.setItem('selectedLanguage', lang.id);
          this.util.publishSideMenu('');
          
          this.chMod.detectChanges();
        }
        const general = data && data.data && data.data.general ? data.data.general : null;

        if (general && general !== null) {
          this.util.general = general;
          this.util.appName = general.store_name;
          console.log('general??', general, this.util.general);
        }
        const settings = data && data.data && data.data.settings ? data.data.settings : null;
        if (settings && settings !== null) {
          this.util.direction = settings.appDirection;
          this.util.cside = settings.currencySide;
          this.util.currecny = settings.currencySymbol;
          if (((x) => { try { JSON.parse(x); return true; } catch (e) { return false } })(settings.country_modal)) {
            this.util.countrys = JSON.parse(settings.country_modal);
          }
          console.log(localStorage.getItem('theme'));
          document.documentElement.dir = this.util.direction;
        }

      }
    }, error => {
      console.log(error);
      this.util.apiErrorHandler(error);
    }).catch(error => {
      console.log(error);
      this.util.apiErrorHandler(error);
    });
  }

  getAppSettings() {
    this.api.get_public('v1/setttings/getDefaultSettings').then((data: any) => {
      console.log('app settings', data);
      if (data && data.success === true && data.status === 200) {
        const general = data && data.data && data.data.general ? data.data.general : null;
        if (general && general !== null) {
          this.util.general = data.data.general;
          this.util.appName = general.store_name;
        }
        console.log("GENERAL ==== ", this.util.general);
        console.log("APP ==== ", data.data);

        const settings = data && data.data && data.data.settings ? data.data.settings : null;
        if (settings && settings !== null) {
          this.util.direction = settings.appDirection;
          this.util.cside = settings.currencySide;
          this.util.currecny = settings.currencySymbol;
          if (((x) => { try { JSON.parse(x); return true; } catch (e) { return false } })(settings.country_modal)) {
            this.util.countrys = JSON.parse(settings.country_modal);
          }
          console.log(localStorage.getItem('theme'));
          document.documentElement.dir = this.util.direction;
        }

        const lang = data && data.data && data.data.language ? data.data.language : null;
        if (lang && lang !== null) {
          this.util.translations = JSON.parse(lang.content);
          localStorage.setItem('selectedLanguage', lang.id);
          
          this.chMod.detectChanges();
          this.util.publishSideMenu('');
        }

      }
    }, err => {
      console.log(err);
      this.util.error(this.util.translate('Something went wrong...'));
    }).catch((err) => {
      console.log(err);
      this.util.error(this.util.translate('Something went wrong...'))
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
    }
    if (event instanceof NavigationEnd) {
      // window.scrollTo(0, 0);
      window.scrollTo({ top: 0 });
      const data = this.getTitle(this.router.routerState, this.router.routerState.root);
      console.log('--->>navigation data', data);
      this.titleService.setTitle(data && data[1] ? this.util.translate(data[1]) + ' | ' + this.util.appName :
        this.util.translate('Home') + ' | ' + this.util.appName);
    }

    if (event instanceof NavigationCancel) {
    }
    if (event instanceof NavigationError) {
    }
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
