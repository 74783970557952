<!-- 
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
 -->
<ngx-spinner></ngx-spinner>
<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card mx-4">
            <div class="card-body p-4">
              <form>
                <h1>{{util.translate('Register')}}</h1>
                <p class="text-muted">{{util.translate('Create your account')}}</p>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-user"></i></span>
                  </div>
                  <input type="text" class="form-control" [(ngModel)]="first_name" [ngModelOptions]="{standalone: true}"
                    [placeholder]="util.translate('First Name')" autocomplete="firstname" required>
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-user"></i></span>
                  </div>
                  <input type="text" class="form-control" [(ngModel)]="last_name" [ngModelOptions]="{standalone: true}"
                    [placeholder]="util.translate('Last Name')" autocomplete="lastname" required>
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">@</span>
                  </div>
                  <input type="text" class="form-control" [(ngModel)]="email" [ngModelOptions]="{standalone: true}"
                    [placeholder]="util.translate('Email')" autocomplete="email" required>
                </div>

                <div class="row mb-3">
                  <div class="col-md-3">
                    <input type="text" class="form-control" [(ngModel)]="country_code"
                      [ngModelOptions]="{standalone: true}" [placeholder]="util.translate('91')" autocomplete="country code" required>
                  </div>
                  <div class="col-md-9">
                    <input type="number" class="form-control" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}"
                      [placeholder]="util.translate('Phone Number')" autocomplete="phone" required>
                  </div>
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" [(ngModel)]="password"
                    [ngModelOptions]="{standalone: true}" [placeholder]="util.translate('Password')" autocomplete="new-password" required>
                </div>

                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" [(ngModel)]="confirm_password"
                    [ngModelOptions]="{standalone: true}" [placeholder]="util.translate('Repeat password')" autocomplete="new-password"
                    required>
                </div>
                <button type="button" class="btn btn-block btn-success" (click)="createAccount()">{{util.translate('Create Account')}}</button>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </main>
</div>