/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    name: 'Categories',
    url: '/categories',
    icon: 'cil-applications'
  },
  {
    name: 'Sub Categories',
    url: '/subcategories',
    icon: 'cil-align-center'
  },
  {
    name: 'Brands',
    url: '/brands',
    icon: 'cil-bold'
  },
  {
    name: 'Users',
    url: '/users',
    icon: 'cil-user'
  },
  {
    name: 'Banners',
    url: '/banners',
    icon: 'cil-mobile-landscape'
  },
  {
    name: 'Offers',
    url: '/offers',
    icon: 'cil-badge'
  },
  {
    name: 'Orders',
    url: '/orders',
    icon: 'cil-cart'
  },
  {
    name: 'Products',
    url: '/products',
    icon: 'cil-basket'
  },
  {
    name: 'General',
    url: '/general',
    icon: 'cil-info'
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'cil-cog'
  },
  {
    name: 'Notification',
    url: 'app-notifications',
    icon: 'cil-bell'
  },
  {
    name: 'Languages',
    url: '/languages',
    icon: 'cil-translate'
  },
  {
    name: 'Payments',
    url: '/payments',
    icon: 'cil-money'
  },
  {
    name: 'Shipping Settings',
    url: '/shippings',
    icon: 'cil-truck'
  },
  {
    name: 'Tax Class',
    url: '/tax-class',
    icon: 'cil-functions'
  },
  {
    name: 'Zip Codes',
    url: '/zipcodes',
    icon: 'cil-barcode'
  },
  {
    name: 'Mail Settings',
    url: '/mail-settings',
    icon: 'cil-at'
  },
  {
    name: 'Return Request',
    url: '/returns',
    icon: 'cil-backspace'
  },
  {
    name: 'Contact Forms',
    url: '/contact-forms',
    icon: 'cil-info'
  },
  {
    name: 'App Pages',
    url: '/app-pages',
    icon: 'cil-description'
  },
  {
    name: 'Buy Now',
    url: 'https://codecanyon.net/user/bunchdeveloper',
    icon: 'icon-layers',
    variant: 'danger',
    attributes: { target: '_blank', rel: 'noopener' }
  }
];
