<!-- 
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
 -->
<ngx-spinner></ngx-spinner>
<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <div class="card-group">
                        <div class="card p-4">
                            <div class="card-body">
                                <form>
                                  
                                    <h1>{{util.translate('Login')}}</h1>
                                    <p class="text-muted">{{util.translate('Sign In to your account')}}</p>

                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="cil-at"></i></span>
                                        </div>
                                        <input type="email" class="form-control" [(ngModel)]="email" [placeholder]="util.translate('Email address')"
                                          autocomplete="email" required [ngModelOptions]="{standalone: true}">
                                    </div>

                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-lock"></i></span>
                                        </div>
                                        <input type="password" class="form-control" [(ngModel)]="password" [placeholder]="util.translate('Password')"
                                          autocomplete="current-password" required [ngModelOptions]="{standalone: true}">
                                    </div>

                                    <div class="row">
                                        <div class="col-6">
                                            <button type="button" class="btn btn-primary px-4"
                                              (click)="onLogin()">{{util.translate('Login')}}</button>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button type="button" (click)="forgot()" class="btn btn-link px-0">{{util.translate('Forgot password?')}}</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>