/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AuthGuard } from './guard/auth.guard';

import { P404Component } from './pages/error/404.component';
import { P500Component } from './pages/error/500.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { SetupAuthGuard } from './setupGuard/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    },
    canActivate: [SetupAuthGuard]
  },
  {
    path: 'setup',
    component: RegisterComponent,
    data: {
      title: 'Setup'
    }
  },
  {
    path: 'forgot',
    component: ForgotComponent,
    data: {
      title: 'Forgot Password'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./pages/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./pages/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./pages/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'icons',
        loadChildren: () => import('./pages/icons/icons.module').then(m => m.IconsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./pages/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./pages/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'banners',
        loadChildren: () => import('./pages/banner/banner.module').then(m => m.BannerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'user-details',
        loadChildren: () => import('./pages/user-details/user-details.module').then(m => m.UserDetailsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'order-details',
        loadChildren: () => import('./pages/order-details/order-details.module').then(m => m.OrderDetailsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'categories',
        loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'subcategories',
        loadChildren: () => import('./pages/subcategories/subcategories.module').then(m => m.SubcategoriesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'banner',
        loadChildren: () => import('./pages/banner/banner.module').then(m => m.BannerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'offers',
        loadChildren: () => import('./pages/offers/offers.module').then(m => m.OffersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'orders',
        loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'products',
        loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'product-details',
        loadChildren: () => import('./pages/product-details/product-details.module').then(m => m.ProductDetailsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'brands',
        loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'general',
        loadChildren: () => import('./pages/general/general.module').then(m => m.GeneralModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/app-settings/app-settings.module').then(m => m.AppSettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'languages',
        loadChildren: () => import('./pages/languages/languages.module').then(m => m.LanguagesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-languages',
        loadChildren: () => import('./pages/manage-languages/manage-languages.module').then(m => m.ManageLanguagesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'payments',
        loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'zipcodes',
        loadChildren: () => import('./pages/pincodes/pincodes.module').then(m => m.PincodesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'shippings',
        loadChildren: () => import('./pages/shippings/shippings.module').then(m => m.ShippingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tax-class',
        loadChildren: () => import('./pages/tax-class/tax-class.module').then(m => m.TaxClassModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'returns',
        loadChildren: () => import('./pages/returns/returns.module').then(m => m.ReturnsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'app-notifications',
        loadChildren: () => import('./pages/app-notifications/app-notifications.module').then(m => m.AppNotificationsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'mail-settings',
        loadChildren: () => import('./pages/mail-settings/mail-settings.module').then(m => m.MailSettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contact-forms',
        loadChildren: () => import('./pages/contact-forms/contact-forms.module').then(m => m.ContactFormsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'app-pages',
        loadChildren: () => import('./pages/app-pages/app-pages.module').then(m => m.AppPagesModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
