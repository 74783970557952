import { INavData } from '@coreui/angular';
/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { UtilService } from './../../services/util.service';
import { ChangeDetectorRef, Component } from '@angular/core';
import { navItems } from '../../_nav';
import * as moment from 'moment';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  // public navItems = navItems;
  public currentYear: any;
  public navData: INavData[] = [];
  constructor(
    public api: ApiService,
    public util: UtilService,
    private router: Router,
    private chMod: ChangeDetectorRef
  ) {
    console.log('update', this.navData);
    this.currentYear = moment().format('YYYY');
    // this.navData = this.util.navItems;
    setTimeout(() => {
      this.util.navItems.filter(x => x.name = this.util.translate(x.name));
      console.log('navparam', this.util.navItems);
      this.navData = this.util.navItems;
    }, 2000);
  }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  ngOnInit(): void {
    // this.util.getObservable().subscribe(() => {
    //   console.log('subscribe');
    //   const info = navItems.filter(x => x.name = this.util.translate(x.name));
    //   this.navData = [...info];
    // });
  }

  logout() {
    this.util.show();
    this.api.post_private('v1/auth/logout', {}).then((data) => {
      this.util.hide();
      console.log(data);
      localStorage.removeItem('uid');
      localStorage.removeItem('token');
      this.router.navigateByUrl('/login');
    }, error => {
      this.util.hide();
      console.log(error);
      this.util.apiErrorHandler(error);
    }).catch(error => {
      this.util.hide();
      console.log(error);
      this.util.apiErrorHandler(error);
    });
  }

  openGeneral() {
    this.router.navigate(['general']);
  }

  openSettings() {
    this.router.navigate(['settings']);
  }

}
