<!-- 
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : FullAppBundle Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
 -->
<ngx-spinner></ngx-spinner>
<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <div class="card-group">
                        <div class="card p-4">
                            <div class="card-body">
                                <form *ngIf="div === 1">

                                    <h1>{{util.translate('Reset Password')}}</h1>
                                    <p class="text-muted">{{util.translate('Send OTP')}}</p>

                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="cil-at"></i></span>
                                        </div>
                                        <input type="email" class="form-control" [(ngModel)]="login.email"
                                            [placeholder]="util.translate('Email address')" autocomplete="email" required
                                            [ngModelOptions]="{standalone: true}">
                                    </div>

                                    <div class="row">
                                        <div class="col-6">
                                            <button type="button" class="btn btn-primary px-4"
                                                (click)="goToTabs()">{{util.translate('Send OTP')}}</button>
                                        </div>
                                    </div>

                                </form>

                                <form *ngIf="div === 2">

                                    <h1>{{util.translate('Reset Password')}}</h1>
                                    <p class="text-muted">{{util.translate('Verify OTP')}}</p>

                                    <div class="input-group mb-3">
                                        <ng-otp-input (onInputChange)="onOtpChange($event)"
                                            [config]="{length:6,inputStyles:{'width': '30px','height': '30px','border':'1px solid lightgray','background-color':'white'}}">
                                        </ng-otp-input>
                                    </div>


                                    <div class="row">
                                        <div class="col-6">
                                            <button type="button" class="btn btn-primary px-4"
                                                (click)="onOTP()">{{util.translate('Verify')}}</button>
                                        </div>
                                    </div>

                                </form>

                                <form *ngIf="div === 3">

                                    <h1>{{util.translate('Reset Password')}}</h1>
                                    <p class="text-muted">{{util.translate('Update Password')}}</p>

                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-lock"></i></span>
                                        </div>
                                        <input type="password" class="form-control" [(ngModel)]="passwords.password" [placeholder]="util.translate('Password')"
                                          autocomplete="current-password" required [ngModelOptions]="{standalone: true}">
                                    </div>

                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-lock"></i></span>
                                        </div>
                                        <input type="password" class="form-control" [(ngModel)]="passwords.confirm" [placeholder]="util.translate('Confirm Password')"
                                          autocomplete="current-password" required [ngModelOptions]="{standalone: true}">
                                    </div>


                                    <div class="row">
                                        <div class="col-6">
                                            <button type="button" class="btn btn-primary px-4"
                                                (click)="updatePassword()">{{util.translate('Update')}}</button>
                                        </div>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>